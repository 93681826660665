body {
    background-color: rgb(var(--color-black));
    font-family: var(--font-family-primary);
    font-size: 2.14lvh;
}
/// For Filters
// @media (max-width: 1000px) {
//   .body {
//     font-size: .7rem;

//   }
// }

// @media (max-width: 765px) {
//   .body {
//     font-size: 1px !important;

//   }
// }

.app {
  background-color: #F9F4EE;
}

p {
    margin: 0;
    padding: 0;
    font-family: Luna;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-body);
    line-height: 26px;
}

h1 {
    font-size: var(--font-size-h1);
    font-family: Merriweather;
    font-weight: var(--font-weight-bold);
    line-height: 27vl;
}

h2 {
    font-size: var(--font-size-h2);
    font-family: PT Sans;
    font-weight: var(--font-weight-semiBold);
    line-height: 28px;
}

h3 {
    font-size: var(--font-size-h3);
    font-family: Luna-Expanded;
    font-weight: var(--font-weight-medium);
    line-height: 20px;
}

link {
    text-decoration: none;

}

// Enforce full page layout from the root

html,
body,
#root {
    display: contents;
    flex-direction: column;
    flex-grow: 1;
}

html {
    min-height: 100vh;
}

.hero {
  // other styles here...

  @media screen and (max-width: 1024px) { // medium screens and below
    margin-left: 20px; // adjust as needed
  }

  @media screen and (max-width: 600px) { // small screens
    margin-left: 10px; // adjust as needed
  }
}


// .app {
//   margin-bottom: 10%;
// }

//TokenCard styles

$quantityTextColor: rgb(202, 187, 160);


.card {
    border-radius: 10px;
    border: 1px solid rgb(67,41,24);
    overflow: hidden;
    padding: var(--spacing-xlarge);
    position: relative;

    &::before {
        backdrop-filter: blur(24px);
        background: rgb(249,244,238);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.cardInner {
    position: relative;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-small);
    position: relative;
    margin-top: -8px;
}

.welcomeMessage {
  text-align: center;  
  margin-bottom: 5%;
  position: left;
  padding-left: 20%;
  padding-right: 20%;
}

.title {
    color: $quantityTextColor;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semiBold);
    margin: 0;
}

.imageWrap {
    position: relative;
    width: 100%;
    padding-top: 100%; // Maintain the aspect ratio of the image
    overflow: hidden;
}

.scrollButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #A48A7B;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.form {
    margin-top: var(--spacing-large);
}

.inputWrap {
    display: flex;
    margin-bottom: var(--spacing-xxlarge);
    position: relative;
}

.input {
    appearance: none;
    background-color: #ffffff;
    border-radius: var(--border-radius-regular);
    border: 1px solid rgba(var(--color-dark-grey), 0.12);
    color: $quantityTextColor;
    flex-grow: 1;
    padding: var(--spacing-regular) var(--spacing-small);

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.inputWithError {
    border: 1px solid rgb(var(--color-state-error));
    outline: rgb(var(--color-state-error));
}

.inputError {
    position: absolute;
    top: 100%;
    margin-top: var(--spacing-xxsmall);
    font-size: var(--font-size-h3);
    color: rgb(var(--color-state-error));
}

.inputHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-small);
}

.inputContent {
    color: $quantityTextColor;
}

.quantityLabel {
    color: $quantityTextColor;
  }

.quantityInput {
    opacity: 1;
}
// .remainingCounter {
//     background: rgba(var(--color-black), 0.3);
//     border-radius: var(--border-radius-regular);
//     bottom: var(--spacing-small);
//     color: rgb(var(--color-white));
//     padding: var(--spacing-xsmall);
//     position: absolute;
//     right: var(--spacing-small);
// }

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 300px !important;
    max-height: 300px !important;
    object-fit: contain; 
}


.inputWrap {
    display: flex;
    margin-top: 8px;
    margin-bottom: var(--spacing-xxlarge);
    position: relative;
}

.input {
    appearance: none;
    background-color: #ffffff;
    border-radius: rgb(67,41,24);
    border: 2px solid rgba(var(--color-dark-grey), 0.12);
    color: rgb(67,41,24);
    flex-grow: 1;
    padding: rgb(67,41,24);

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.inputWithError {
    border: 1px solid rgb(var(--color-state-error));
    outline: rgb(var(--color-state-error));
}

.inputError {
    position: absolute;
    top: 100%;
    margin-top: var(--spacing-xxsmall);
    font-size: var(--font-size-h3);
    color: rgb(var(--color-state-error));
}

.inputHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-small);
}

.inputContent {
    color: rgb(67,41,24);
}

.quantityLabel {
    color: rgb(67,41,24);
}

.quantityInput {
    opacity: 1;
}

.breakdownShowing {
    opacity: 0;
}

.infoIcon {
    cursor: pointer;
    height: 20px;
    width: 20px;

    > img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}

.modalHeader {
    margin: 0;
}

.modalDescription {
    color: rgb(var(--color-light-grey));
    line-height: 1.6;
}

.purchaseButtons {
    display: grid;
    gap: var(--spacing-regular)
}

.artistInfo {
    color: rgb(67,41,24);
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    margin-bottom: 8px;
    a {
        color: rgb(164, 138, 123);
    
        &:hover {
          color: rgb(67, 41, 24);
        }
     }
}

.grid {
    display: grid;
    object-position: center;
    gap: var(--spacing-xxlarge);
    grid-template-columns: 1fr;
    

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        object-position: center;

    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        object-position: center;

    }
}

.gridTokenPage {
  display: grid;
  object-position: center;
  gap: var(--spacing-xxlarge);
  grid-template-columns: 1fr;
  justify-items: center;


  @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;

  }

  @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;

  }
}


//Purchase Button

.cardButton {
    appearance: none;
    background-color: transparent;
    border-radius: var(--border-radius-large);
    border: 2px solid rgb(var(--color-primary));
    color: rgb(var(--color-white));
    cursor: pointer;
    font-family: Luna;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semiBold);
    min-height: 50px;
    padding: var(--spacing-small);
    // width: 100%;

    &:disabled {
        border: 2px solid rgb(var(--color-dark-grey-disabled));
        color: rgb(var(--color-light-grey-disabled));
        cursor: not-allowed;
    }
}

.CardButtonTwo { 
    appearance: none;
    background-color: rgb(67,41,24);
    border: 0;
    border-radius: var(--border-radius-large);
    color: rgb(var(--color-white));
    cursor: pointer;
    font-family: PT Sans;
    font-size: 0em;
    max-height: 50px;
    padding: var(--spacing-small);
    width: 50% !important;
    margin-top: -.1%;
    margin-left: 0%;

    &:disabled {
        background-color: rgb(var(--color-dark-grey-disabled));
        color: rgb(var(--color-light-grey-disabled));
        cursor: not-allowed;
    }
}

@media (min-width: 768px) {
  .CardButtonTwo {
    width: 50%;
    // justify-content: ;


  }
}





.CardButtonThree { 
    appearance: none;
    background-color: rgb(67,41,24);
    border: 0;
    border-radius: var(--border-radius-large);
    color: rgb(var(--color-white));
    cursor: pointer;
    font-family: PT Sans;
    font-size: 1.2em;
    max-height: 50px;
    height: 50px;
    width: 15rem !important;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 0%;
}


// @media (max-width: 480px) {
//   .CardButtonTwo {
//     width: auto;
//     margin-left: 20%;
//     margin-right: 20%;
//     margin-top: 8%;
//     margin-bottom: 5%;

//   }
// }


.backToTop{
  padding-left: 2%;
  font-family: inherit !important;
  color: #432918;
}

.CardButton {
    appearance: none;
    background-color: rgb(var(--color-primary));
    border-radius: var(--border-radius-large);
    border: 2px solid rgb(var(--color-primary));
    color: rgb(var(--color-white));
    cursor: pointer;
    font-family: Luna;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semiBold);
    min-height: 50px;
    padding: var(--spacing-small);
    width: 100%;

    &:disabled {
        border: 2px solid rgb(var(--color-dark-grey-disabled));
        color: rgb(var(--color-light-grey-disabled));
        cursor: not-allowed;
    }
}

.soldOutButton {
    cursor: not-allowed;
}

.purchaseButtons {
    display: grid;
    gap: var(--spacing-regular)
}

.infoIcon {
    cursor: pointer;
    height: 20px;
    width: 20px;

    > img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}

.breakdownShowing {
    opacity: 0;
}


//// Header 
.header {
    background-color: rgb(249,244,238);
    position: sticky;
    top: 0;
    z-index: 0;
    width: 100%;
}

.headerContainer {
    display: grid;
    grid-template-areas: 'logo wallet'
                          'counters counters';
    padding: var(--spacing-large);
    gap: var(--spacing-large);

    @media screen and (min-width: 100vw) {
        grid-template-areas: 'logo counters wallet';
        grid-template-columns: 1fr auto 1fr;
    }
}

.logo {
    grid-area: logo;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1024px) {
        margin: 0;
    }
}

.timers {
    grid-area: counters;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        justify-content: center;
    }
}

.connectedWallet {
    grid-area: wallet;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 1024px) {
        justify-content: flex-end;
    }
}

.connectedWallet span {
    color: rgb(67, 41, 23); // Change the color here
}


.countdown {
    display: flex;
    border-radius: var(--border-radius-regular);
    background-color: rgba(255, 255, 255, 0.08);
    padding: var(--spacing-xsmall) var(--spacing-regular);
}

.countdownLive {
    background-color: rgba(var(--color-state-success), 0.2);
    border: 1px solid rgba(var(--color-state-success), 0.4);
}

.countdownTitle {
    color: rgb(67, 41, 23); // Change the color here
    text-transform: uppercase;
    font-family: var(--font-family-secondary);
}

.contentEmphasis {
    color: rgb(var(--color-white));
    font-weight: var(--font-weight-bold);
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;  // This is optional and is used to vertically align the items in the middle
  }


  .iframeContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  //Checkout Stripe

  .modal {
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.4); 
  }
  
  .modalContent {
    background-color: #F9F4EE;
    margin: 15% auto; 
    width: 70%; 
  }

  #checkout-with-card-iframe {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999; /* a high value to ensure it's above other content */
    transform: translate(-50%, -50%); /* to perfectly center the iframe */
    /* add dimensions as needed */
    width: 90%;
    height: 90%;
  }


  // Home Page Cards
  .outerCard {
    transform: var(--tw-transform);
    border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
    border-width: 1px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition-duration: 200ms;
    outline: rgb(218,218,218) solid 1px !important;
  }


  
  @media screen and (max-width: 1024px) {
    .box {
      margin-left: 8%;
      margin-right: 8%;

    }
  }
  
  @media screen and (max-width: 600px) {
    .box {
      margin-left: 8%;
      margin-right: 8%;
    }
  }
  


.tokenDetailsbox {
    border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
    border-width: 1px;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    transition-duration: 200ms;
    outline: rgb(218,218,218) solid 1px !important;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
    transition: ease-in-out .3s;
    text-decoration: none !important;
    margin-top: 10%;
    min-width: 100px;
  }

.box:hover {
  top: calc(50% - 2px);
  border: .3px solid rgba(242, 244, 248, 0);
  box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 5px rgba(0,0,0,.1);
  cursor: pointer;
}

.box:active {
  top: 50%;
  box-shadow: 0 5px 10px rgba(0,0,0,.05), 0 0 2px rgba(0,0,0,.1);
  transition: ease-in-out .1s;
}

  
  .topCard {
    border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
    border-bottom-width: 10px;
    padding: 16px;
    border-bottom: rgb(218,218,218) solid 1px !important;
    border-bottom-width: rgb(218,218,218) 1px solid
    
  }

  .tokenDetailstopCard {
    border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
    border-bottom-width: 1px;
    padding: 16px;
    outline: rgb(218,218,218) solid 1px !important;
    border-bottom-width: rgb(218,218,218) 1px solid
  }
  
  .imageCard {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    color: transparent;
    border-style: solid;
    display: block;
    // vertical-align: middle;
    max-width: 100%;
    object-fit: contain;
    outline: rgb(218,218,218) solid 1px !important;
  }
  
.bottomCard {
  padding: 16px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  justify-content: space-between;
  flex: 1 1 0%;
  margin-top: 5%;
  margin-bottom: 1%;
  border-top: 10px;
}

  .country {
    margin: 0px;
    font-family: inherit;
    line-height: inherit;
    overflow-y: scroll;
    overscroll-behavior-x: contain;
    min-height: 100%;
    color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
    -webkit-font-smoothing: antialiased;
    background-color: var(--bg-primary-unwrapped);
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    outline: none;
  }


  .tokenDetailstitleCard {
    font-size: .85rem;
    margin-top: 3%;
    font-weight: inherit;
    letter-spacing: 0.03em;
    overflow-wrap: break-word;
    line-height: 130%;
    text-align: left;
    word-break: break-word;
    // outline: rgb(218,218,218) solid 1px !important;
  }


  .tokenDetailsartistCard {
    font-size: .75rem;
    margin: 0px;
    font-family: inherit;
    line-height: inherit;
    // overflow-y: scroll;
    overscroll-behavior-x: contain;
    min-height: 100%;
    color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
    -webkit-font-smoothing: antialiased;
    background-color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  
    --tw-text-opacity: 1;
  }

  .titleCard {
    color: rgb(67,41,24) !important;

    font-family: Merriweather;
    font-size: 1.5rem;
    margin: 0px;
    font-weight: inherit;
    letter-spacing: 0.03em;
    overflow-wrap: break-word;
    line-height: 130%;
    text-align: left;
    word-break: break-word;
    // outline: rgb(218,218,218) solid 1px !important;
  }
  

  .artistAndPriceCard {
    display: flex;
    align-items: center;
  }
  
  .artistCard {
    margin-top: 3%;
    font-family: Merriweather;
    font-size: 1.5rem;
    font-weight: 600;
    color: #9D8375 !important;
    line-height: inherit;
    // overflow-y: scroll;
    overscroll-behavior-x: contain;
    min-height: 100%;
    color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
    -webkit-font-smoothing: antialiased;
    background-color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  
    --tw-text-opacity: 1;
  }
  
  .price {
    font-family: Merriweather;
    font-size: 1.5rem;
    font-weight: 600;
    color: #9D8375 !important;
    line-height: inherit;
    min-height: 100%;

  }
  
  .priceCard {
    margin-left: auto;
  }


.countryCard {
  margin: 0;
  font-family: PT Sans;
  font-size: 1rem !important;
  font-weight: bolder;
  color: #A48A7B !important;
  margin-top: 3%;
  line-height: inherit;
  overscroll-behavior-x: contain;
  font-size: small;
  min-height: 100%;
  color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  background-color: rgba(var(--bg-primary-unwrapped), var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;

}

.saleType {
  font-family: PT Sans;
  font-weight: bolder;


  font-size: 1rem;

}

//   .lineBorder {
//     width: 110%;
//     margin: auto;
//     margin-top: 5%;
//     margin-bottom: 5%;
//     border: 10px #000000;
//     box-shadow: 0 -1px 0 #000;

// }

// .filterDropdown1 {
//   margin-left: 20%;  /* Adjust as needed */
//   align-self: center;

//   @media (max-width: 768px) { // adjust this value to target the desired screen sizes
//     width: 18% !important;
//     margin-left: 7.75%;
//     display: none;

//   }
// }


// .filterDropdown2 {
//   margin-left: 2px;  /* Adjust as needed */
//   align-self: center;
//   overflow: hidden;


//   @media (max-width: 768px) { // adjust this value to target the desired screen sizes
//     width: 18% !important;
//     margin-left: 7.75%;
//     display: none;

//   }
// }
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 18% !important;
    margin-left: 7.75%;
    display: none;
  }
}

.mobileFilterButton{
  position: relative;
  color: #432918;
  padding-right: 63%;

}

.filterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    width: 18% !important;
    margin-left: 7.75%;
    display: none;
  }
}

.filterDropdown {
  position: relative;

  &:not(:first-child) {
    margin-top: 10px;
  }

  .menu.visible {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
  }
}

.filterDropdownLast {
  position: relative;

  &:not(:first-child) {
    margin-top: 10px;
    margin-bottom: 100%;
  }

  .menu.visible {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
  }
}

// .sidebar.with-padding {
//   padding-top: 40px; /* Adjust the desired padding value */
// }

.sidebar {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-left: 0;
  margin-top: 2%;
  position: sticky;
  top: 93px;


  @media (max-width: 1010px) {
  display: none;
  }
}


.tokenGridContainer {
  flex: 1;
  padding-left: 15px;
}

.gridContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.filterSearchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 10px;
}

.SearchContainer {
  overflow: hidden;
  margin-bottom: 50%;
}

.searchBar {
  align-items: center;
  overflow: hidden;
}


// Mobile Filter Styles

.filterButtonContainer {
  display: none; // Hide the filter button by default on all devices except mobile


  @media (max-width: 768px) {
    display: block; // Show the filter button on mobile devices
    margin-bottom: 1rem;
    padding-left: 30%;  
    padding-right: 30%;  

  }
}

.filterDropdownContainer {
  display: none; // Hide the filter dropdown container by default

  @media (max-width: 768px) {
    display: block; // Show the filter dropdown container on mobile devices
  }

  .filterDropdown {
    // Styles for the dropdown component
    // ...
  }

  button {
    // Styles for the apply button
    // ...
  }
}

.filterModule {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.filterButton {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #f5f5f5;
  border: none;
  text-align: left;
  cursor: pointer;
}

.filterContainer {
  display: none;

  @media (max-width: 768px) {
    display: block;
    padding: 12px;
    background-color: #f5f5f5;
  }
}

.applyButton {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  background-color: #432918;
  color: white;
  border: none;
  cursor: pointer;
}



.desktopSidebar {
  /* By default, the desktop sidebar is visible */
  display: block;
}

.mobileSidebar {
  /* By default, the mobile button and sidebar are not visible */
  display: none;
}

/* When the viewport is 600px or less, switch the displays */
@media screen and (max-width: 763px) {
  .desktopSidebar {
    display: none;
  }

  .mobileSidebar {
    display: block;
  }}
// .ui.selection.dropdown {
//     cursor: pointer;
//     word-wrap: break-word;
//     line-height: 1em;
//     white-space: normal;
//     outline: 0;
//     transform: rotateZ(0);
//     min-width: 6vw;
//     min-height: 2.71428571em;
//     background: #fff;
//     display: inline-block;
//     padding: 0.78571429em 2.1em 0.78571429em 1em;
//     color: rgba(0,0,0,.87);
//     box-shadow: none;
//     border: 1px solid rgba(34,36,38,.15);
//     border-radius: 0.28571429rem;
//     transition: box-shadow .1s ease,width .1s ease;
// }

// .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
//       color: rgba(191,191,191,.87);
    
// }

// ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
//     cursor: pointer;
//     position: absolute;
//     width: auto;
//     height: auto;
//     line-height: 1.21428571em;
//     top: 0.78571429em;
//     right: 1em;
//     z-index: 3;
//     margin: -0.78571429em;
//     padding: 0.91666667em;
//     opacity: .8;
//     transition: opacity .1s ease;
// }
  


  

  /// Token Page
  /// 

 .centerContainer {
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}

.backHomelink {
  text-align: center;
}



 .creatorLabel {
    font-weight: 400;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-size: 1vw; // updated
    line-height: 12px; // updated
    color: rgb(18, 18, 18);
    opacity: 0.3;
    margin-bottom: 8px; // updated
    text-align: left !important;
    margin-top: 4%;
    // margin-left: 15%;


}
  
  .artistName {
    display: flex;
    align-items: center;
    font-family: PT Sans;
    font-size: 20px;
    color: #A48A7B !important;
    text-decoration: none;
    background-color: transparent;
    touch-action: manipulation;
    outline: none;
    // margin-left: 15%;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .totalLabel {
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: 1.25em; // updated
    line-height: 18px; // updated
    color: #A48A7B !important;
    opacity: 0.3;
    margin-bottom: 8px; // updated
    text-align: left !important;
    margin-top: 3%;
    // margin-left: 15%;
  }
  
  .totalWrap {
    align-items: center;
    display: flex;
    margin-top: 0%;
  }
  
  .totalLogo {
    vertical-align: text-bottom;
    overflow: hidden;
  }
  

  .totalNumbers {
    color: #a48a7b!important;
    font-family: Merriweather;
    font-size: 20px;
    font-weight: 700!important;
    letter-spacing: 0;
    line-height: 34px;
}
  
  .descriptionWrap {
    display: fill;
    margin-top: 2%;
    min-width: 40vw;

     @media screen and (max-width: 915px) { // updated to 915px
        margin-top: 5%; // switch to column for smaller screens
        min-width: 0vw;


    }
}
  
  
  .description {
    font-family: PT Sans;
    font-weight: 200;
    color: #A48A7B !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
    touch-action: manipulation;
    outline: none;
    font-family: PT Sans;
    font-size: 20px;
    // margin-left: 15%;
    margin-bottom: 5%;
    // min-width: 300px;
 }

//  @media (max-width: 690px) {
//   .description {
//     // margin-left: 30%;
//     // margin-right: 0%;


    .Label {
      font-family: Merriweather;
      font-weight: 700;
      color: #432918 !important;
      letter-spacing: 0px;
      text-transform: uppercase;
      font-size: 19px; // updated
      line-height: 18px; // updated
      margin-bottom: 3%; // updated
      text-align: left !important;
      margin-top: 1%;
    
      }
    

      .LabelDescription {
        font-family: Merriweather;
        font-weight: 700;
        color: #432918 !important;
        letter-spacing: 0px;
        text-transform: uppercase;
        font-size: 19px; // updated
        line-height: 18px; // updated
        margin-bottom: 3%; // updated
        text-align: left !important;
        margin-top: 3%;
        }
      
  
  .infoWrap {
    width: 100%;
  }
  
  .topinfoLine {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: 5%; 
}

.infoLine {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: 3%; 
}

// .tokengrid {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between; /* This will add some space between the items */
// }

  

  .infoRight {
      font-weight: 400;
      font-family: "PP Fragment";
      letter-spacing: 0px;
      font-size: 18px;
      line-height: 22px;
      color: rgb(18, 18, 18);
      margin-right: 5%;
      }

    .infoLeft {
        font-weight: 400;
        font-family: "PP Fragment";
        letter-spacing: 0px;
        font-size: 18px;
        line-height: 22px;
        color: rgb(18, 18, 18);
        margin-left: 5%;
          }

  
    .titleToken {
        font-weight: 600;
        font-weight: bolder;
        font-size: 2em;
        font-family: "Merriweather";
        line-height: 66px;
        letter-spacing: 0px;
        color: rgb(67,41,24) !important;
        display: flex;
        align-items: center;
        touch-action: manipulation;
        outline: none;
        // margin-left: 15%;
        margin-bottom: 10px;
    

      }

  $outline: 1px solid rgb(66, 153, 225) !important;

  // .artImage {
  //   vertical-align: right;
  //   border-style: none;
  //   min-width: 1px;
  //   display: flex-end;
  //   width: 600px;
  //   height: 600px;
  //   object-fit: contain;
  //   object-position: right;
  //   margin-top: 3%;
  //   margin-left: 12%;

  // }



  // .artImage {
  //   vertical-align: right;
  //   border-style: none;
  //   min-width: 1px;
  //   display: flex-end;
  //   width: 600px;
  //   height: 600px;
  //   object-fit: contain;
  //   object-position: right;
  //   margin-top: 3%;
  //   margin-left: 12%;

  // }
  
  // // style for portrait images
  // .portrait {
  //   vertical-align: right;
  //   border-style: none;
  //   min-width: 1px;
  //   display: flex-end;
  //   width: 600px;
  //   height: 600px;
  //   object-fit: contain;
  //   object-position: right;
  //   margin-top: 3%;
  //   margin-left: 12%;  }
  
  // // style for landscape images
  // .landscape {
  //   display: flex-end;

  
  //   @media (max-width: 768px) {
  //     object-position: center;
  //   margin-left: 120%;  }
  //   }
  ////////////////////////////////////////////////////////////////
  .artFrame {
    border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
    border-bottom-width: 1px;
    padding: 0.8vw;
    outline: rgb(218,218,218) solid 1px !important;
    border-bottom-width: rgb(218,218,218) 1px solid;
    background-color: #ffffff;
  }


  .artImage, .portrait, .landscape {
    position: relative;
    min-width: 1px;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
    width: 600px;
    height: 600px;
    object-fit: contain;
    object-position: center;
    margin-top: 3%;
    margin-left: 1%;
    background-color: #FFFFFF;
  
    /* Add white frame */
    border: 2px solid #DADADA;
    border-radius: 10px;
    padding: 5%;
  
    @media (max-width: 1410px) {
      margin-left: 0%;
    }
  
    @media (max-width: 1220px) {
      margin-left: -7%;
      width: 525px;
      height: 525px;
      padding: 6%;
    }
  
    @media (max-width: 1110px) {
      margin-left: -7%;
      padding: 7%;
    }
    
    @media (max-width: 1161px) {
      padding-left: 10%;
      width: 425px;
      height: 425px;
      padding: 8%;
    }
  
    @media (max-width: 918px) {
      // margin-left: 30%;
      // padding: 10%;
      margin-right: 30%;
    }
  
    @media (max-width: 610px) {
      width: 450px;
      height: 450px;
      margin-left: -4.78%;
      // margin-right: 5%;

      // object-position: center;
      padding: 5%;
    }
  
    @media (max-width: 530px) {
      width: 375px;
      height: 375px;
      margin-left: 0%;
      object-position: center;
      padding: 5%;
    }
  
    @media (max-width: 430px) {
      width: 350px;
      height: 350px;
      margin-left: 0%;
      object-position: center;
      padding: 5%;
    }
  
    @media (max-width: 400px) {
      width: 320px;
      height: 320px;
      margin-right: 3.2%;
      object-position: center;
      padding: 5%;
    }
  }


.Backlinks {
  overflow: hidden;
  margin-bottom: 2%;
  font-family: PT Sans;
  color: #432918;
  top: -200px;
left: 100px;
margin-bottom: -200px;
}

  
  .rightSide {
    align-items: flex-start;
    padding: 2px;
    margin: 1px;
    justify-content: flex-start;
    padding-left: 3%;
  }

  @media (max-width: 1400px) {
    .rightSide {
      padding-left: 0%;
  
    }
  }

  @media (max-width: 1060px) {
    .rightSide {
      margin-right: 10%;


    }
}

  @media (max-width: 768px) {
    .rightSide {
      margin-left: 5%;


    }
}

@media (max-width: 480px) {
    .rightSide {
      margin-left: 5%;

    }
}
 


  .leftSide {
    padding: 2px;
    margin: 1px;
    justify-content: flex-end;
    margin-right: 6%;
  

  }

  @media (max-width: 400px) {
    .leftside{
      margin-right: 0%;
      padding-left: 2%;
      padding-right: 2%;

    }

  }


.fullContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    overflow: hidden;
    margin-bottom: 7.5%;
  }

.tokenPage {
margin-bottom: 15%; 
 }


// .infoUnderImage {
//   margin-left: 32rem;
//   margin-right: 32rem;
//   margin-top: 24rem;
//   outline: $outline;
// }


.fullContainer {
    display: flex;
    // margin-top: 40px;
    flex-direction: row; // default to row

    @media screen and (max-width: 915px) { // updated to 915px
        flex-direction: column; // switch to column for smaller screens
        align-items: center; // center items horizontally
        justify-content: center; // center items vertically
    }
}

// .rightSide {
//     flex: 0.6;  // Updated value
//     min-width: 1px;
//     flex-direction: column;
//     display: flex;
//     margin-left: 60px;
//     // align-items: center; // center text within rightSide

//     @media screen and (max-width: 915px) { // updated to 915px
//         margin-left: 0px; // remove left margin for smaller screens
//     }
// }

// .leftSide {
//     position: relative;
//     flex: 0.4;  // Updated value
//     align-items: right; // center text within leftSide
// }

.PurchaseButton {
    overflow: unset;
    margin: 0px;
    height: 100%;
    color: rgb(18, 18, 18);
    font-weight: 400;
    font-size: 16rem;
    font-family: "PP Fragment", sans-serif;
    position: relative;
    background-color: rgb(255, 255, 255);
    outline: solid 1px rgb(66, 153, 225) !important;
  }


  //Discover Tokens Section 

  .token-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.token-card {
    flex: 1 0 30%;  /* Grow, shrink, basis */
    margin: 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .token-card {
        flex: 1 0 45%;
    }
}

@media (max-width: 480px) {
    .token-card {
        flex: 1 0 100%;
    }
}


.bottomTokenContainer {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 10%;
  }
  

  .bottomTokenHeadline{  
    font-weight: 600;
    font-weight: bolder;
    font-size: 2em;
    font-family: "Merriweather";
    line-height: 66px;
    letter-spacing: 0px;
    color: rgb(67,41,24) !important;
    display: flex;
    align-items: center;
    touch-action: manipulation;
    outline: none;
    // margin-left: 15%;
    width: 100%;
    margin-bottom: 10px;
  }

  @media (max-width: 1220px) {
    .bottomTokenHeadline {
      margin-bottom: 5%;
      justify-content: center;

  
    }
  }

    .box2 {
        width: 350px; 
        height: 550px; 
        border-radius: 10px;
        transition-duration: 200ms;
        outline: rgb(218,218,218) solid 1px !important;
        background: #FFFFFF;
        box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
        transition: ease-in-out .3s;
        text-decoration: none !important;
  

      }

      .box {
        top: 50%;
        left: 50%;
        // transform: translate(0%,-50%);
        margin-top: 8%;
        border-color: rgba(var(--bg-primary-unwrapped), var(--tw-border-opacity));
        border-width: 1px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        transition-duration: 200ms;
        outline: rgb(218,218,218) solid 1px !important;
        background: #FFFFFF;
        box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
        transition: ease-in-out .3s;
        text-decoration: none !important;
      }


      //HeaderMenu



@media (max-width: 1100px) {
  .headerMenu {
    display: none;
  }
}


    