body {
    background-color: rgb(var(--color-light-grey));
    font-family: var(--font-family-primary);
}

p {
    margin: 0;
    padding: 0;
    font-family: Luna;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-body);
    line-height: 26px;
}

h1 {
    font-size: var(--font-size-h1);
    font-family: Merriweather;
    font-weight: var(--font-weight-bold);
    line-height: 72px;
}

h2 {
    font-size: var(--font-size-h2);
    font-family: Merriweather-Light;
    font-weight: var( --font-weight-light);
    line-height: 28px;
}

h3 {
    font-size: var(--font-size-h3);
    font-family: Luna-Expanded;
    font-weight: var(--font-weight-medium);
    line-height: 20px;
}

// Enforce full page layout from the root

html,
body,
#root {
    display: contents;
    flex-direction: column;
    flex-grow: 1;
}

html {
    min-height: 100vh;
}

//

$quantityTextColor: rgb(202, 187, 160);


.card {
    border-radius: var(--border-radius-regular);
    border: 1px solid rgba(var(--color-primary), 0.3);
    overflow: hidden;
    padding: var(--spacing-xlarge);
    position: relative;

    &::before {
        backdrop-filter: blur(24px);
        background: rgba(255,255,255,0.06);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.cardInner {
    position: relative;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-large);
    position: relative;
}

.title {
    color: $quantityTextColor;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semiBold);
    margin: 0;
}

.imageWrap {
    position: relative;
    max-width: 10% !important;
    max-height: 10% !important;
    padding-top: 100%; // Maintain the aspect ratio of the image
    overflow: hidden;
}

// .remainingCounter {
//     background: rgba(var(--color-black), 0.3);
//     border-radius: var(--border-radius-regular);
//     bottom: var(--spacing-small);
//     color: rgb(var(--color-white));
//     padding: var(--spacing-xsmall);
//     position: absolute;
//     right: var(--spacing-small);
// }

.image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 10% !important;
    max-height: 10% !important;
    object-fit: contain; // Adjust this property as needed: "contain" will fit the entire image, "cover" will fill the container
}

.form {
    margin-top: var(--spacing-large);
}

.inputWrap {
    display: flex;
    margin-bottom: var(--spacing-xxlarge);
    position: relative;
}

.input {
    appearance: none;
    background-color: rgba(var(--color-dark-grey), 0.12);
    border-radius: var(--border-radius-regular);
    border: 1px solid rgba(var(--color-dark-grey), 0.12);
    color: $quantityTextColor;
    flex-grow: 1;
    padding: var(--spacing-regular) var(--spacing-small);

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.inputWithError {
    border: 1px solid rgb(var(--color-state-error));
    outline: rgb(var(--color-state-error));
}

.inputError {
    position: absolute;
    top: 100%;
    margin-top: var(--spacing-xxsmall);
    font-size: var(--font-size-h3);
    color: rgb(var(--color-state-error));
}

.inputHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-small);
}

.inputContent {
    color: $quantityTextColor;
}

.quantityLabel {
    color: $quantityTextColor;
    margin-bottom: var(--spacing-small);
}

.quantityInput {
    opacity: 1;
}

.breakdownShowing {
    opacity: 0;
}

.infoIcon {
    cursor: pointer;
    height: 20px;
    width: 20px;

    > img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}

.modalHeader {
    margin: 0;
}

.modalDescription {
    color: rgb(var(--color-light-grey));
    line-height: 1.6;
}

.purchaseButtons {
    display: grid;
    gap: var(--spacing-regular)
}

.artistInfo {
    color: $quantityTextColor;
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    margin-bottom: 8px;
    a {
        color: rgb(164, 138, 123);
    
        &:hover {
          color: rgb(67, 41, 24);
        }
     }
}
  