
.header {
    background-color: rgb(255, 255, 255);
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    box-shadow: 0 4px 2px -2px rgb(193, 193, 193);
    top: 0;
    height: 93px;
  }
  
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-small);
    gap: var(--spacing-large);
  
    @media screen and (min-width: 100vw) {
      grid-template-areas: 'logo counters wallet';
      grid-template-columns: 1fr auto 1fr;
    }
  }
  
  .logo {
    display: flex;
    align-items: center;
  
    @media screen and (min-width: 1024px) {
      margin: 0;
    }
  }
  
  .headerMenu {
    display: flex;
    align-items: center;
  
    @media screen and (min-width: 1024px) {
      justify-content: center;
    }
  }
  
  .connectedWallet {
    display: flex;
    justify-content: flex-end;
  
    @media screen and (min-width: 1024px) {
      justify-content: flex-end;
    }
  }
  
  .connectedWallet span {
    color: rgb(67, 41, 23); // Change the color here
  }
  


// .header {
//     background-color: rgb(255, 255, 255);
//     position: sticky;
//     top: 0;
//     z-index: 2;
//     width: 100%;
//     display: flex;
//     box-shadow: 0 4px 2px -2px rgb(193, 193, 193);
//     top: 0;
//     height: 93px;
//   }
  
  


// .headerContainer {
//     display: grid;
//     grid-template-areas: 'logo wallet'
//                           'counters counters';
//     padding: var(--spacing-small);
//     gap: var(--spacing-large);


//     @media screen and (min-width: 100vw) {
//         grid-template-areas: 'logo counters wallet';
//         grid-template-columns: 1fr auto 1fr;
//     }
// }

// .logo {
//     grid-area: logo;
//     display: flex;
//     align-items: center;

//     @media screen and (min-width: 1024px) {
//         margin: 0;
//     }
// }


// .timers {
//     grid-area: counters;
//     display: flex;
//     gap: 12px;
//     flex-wrap: wrap;
//     justify-content: center;

//     @media screen and (min-width: 1024px) {
//         justify-content: center;
//     }
// }

// .connectedWallet {
//     grid-area: wallet;
//     display: flex;
//     justify-content: flex-end;

//     @media screen and (min-width: 1024px) {
//         justify-content: flex-end;
//     }
// }

// .connectedWallet span {
//     color: rgb(67, 41, 23); // Change the color here
// }


// .countdown {
//     display: flex;
//     border-radius: var(--border-radius-regular);
//     background-color: rgba(255, 255, 255, 0.08);
//     padding: var(--spacing-xsmall) var(--spacing-regular);
// }

// .countdownLive {
//     background-color: rgba(var(--color-state-success), 0.2);
//     border: 1px solid rgba(var(--color-state-success), 0.4);
// }

// .countdownTitle {
//     color: rgb(67, 41, 23); // Change the color here
//     text-transform: uppercase;
//     font-family: var(--font-family-secondary);
// }

// .contentEmphasis {
//     color: rgb(var(--color-white));
//     font-weight: var(--font-weight-bold);
// }

// .headerContainer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;  // This is optional and is used to vertically align the items in the middle
//   }
  

//   .your-custom-class::after {
//     content: "Login";
//     /* additional styling to position the text */
//   }
  