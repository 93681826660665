

@media (max-width: 1100px) {
    .headerMenu {
      display: none;
    }
  }



.dropdownItem {
    /* other styles */
  
    &:hover {
      &::after {
        content: "";
        display: block;
        position: absolute;
        transition: .3s;
        transition-timing-function: cubic-bezier(.58,.3,.005,1);
        height: 3px;
        width: 100%;
        left: 0;
        z-index: 2;
        background-color: #cbbba0;
        bottom: 0; // To align the underline at the bottom of the element



      }
    }
  }


  .menu-item {
    position: relative; // Make sure the parent element is relatively positioned
  
    &:hover::after {
      content: "";
      display: block;
      position: absolute;
      transition: .3s;
      transition-timing-function: cubic-bezier(.58,.3,.005,1);
      height: 3px;
      width: 100%;
      left: 0;
      z-index: 2;
      background-color: #cbbba0;
      bottom: 0; // To align the underline at the bottom of the element
    }
  }
  