.footerContainer {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(249, 244, 238);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px 0px;
    transition: background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s;
    padding: 20px 0px 0px;
    overflow: hidden;
  }
  
  .footerMainContent {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-wrap: wrap;
  
    @media (max-width: 780px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .footerSubContainerWrapper {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  
    @media (max-width: 780px) {
      width: 100%;
    }
  
    .footer__social_icons {
      display: flex;
      align-items: center;
  
      svg {
        height: 2em; /* 3 times bigger in the viewbox code */
        width: auto;
        margin-right: 0.5em;
        fill: #a48a7b;
      }
    }
  }
  
  .footerTitles {
    font-size: 25px;
    margin-bottom: -5px;
    font-family: Merriweather-Bold;
    font-weight: bolder;
    color: #432918;
  }
  
  .footerLinkContainer {
    font-family: Merriweather;
    font-size: 1rem;
    font-weight: lighter;
    margin-top: 10px;
    padding-left: 2px; /* reset ul padding */
    list-style-type: none; /* remove default ul bullet points */
  
    a {
      color: #1e1006;
      text-decoration: none;
      display: block;
      margin-bottom: 5px;
    }
  }
  
  .footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer__social_icons {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  
    a {
      color: inherit;
      text-decoration: none;
      margin: 0 10px;
    }
  }
  ////
  .footer__copy {
    font-family: "PT Sans", sans-serif;
    font-size: 22px;
    font-weight: 400;
    fill: #ffffff;
    color: #ffffff;
    background-color: #432918;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .donateButton {
  font-size: 25px;
  font-family: Merriweather;
  
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  
  @media (max-width: 780px) {
    .footerMainContent {
      flex-direction: column;
      align-items: center;
    }
  
    .footerSubContainerWrapper {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  
  .copywrite{
    font-family: Merriweather;
    font-weight: lighter;
    margin-top: 10px;
  }