.hero {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
      justify-content: center;

  }
}

  .herotext {
    position: absolute;
    color: #fff; // choose color based on your image for visibility
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    padding-bottom: 10%;
    transform: translate(-50%, -50%);
    z-index: 2; // put the text above the blur
    margin-top: 2%;

  }

    .herotexth1 {
      font-size: 40px !important; // dynamic font size
      margin-bottom: 1em;
      padding-left: 5%;
      padding-right: 5%;
      font-family: 'Merriweather-Light';
      margin-bottom: 3%;
      @media screen and (min-width: 600px) {
      }
    }

    .herotextp {
      font-size: 30px !important; // dynamic font size
      margin-bottom: 1em;
      padding-left: 5%;
      padding-right: 5%;
      word-break: break-all;
      font-family: 'Merriweather-Light';

      @media screen and (max-width: 600px) {
      }
    }
  



@media (max-width: 600px) {
  .herotextp {
    display: none;

  }
}

@media screen and (max-width: 890px) {
  .herotexth1 {
    margin-top: 5%;

}
}

.heroGrid {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  z-index: 1; // put the blur behind the text

  
  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1280px) {
    gap: calc(var(--spacing-xxlarge) * 5);
    grid-template-columns: 1fr 1fr;
  }

  .HeaderCard__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(186,173,149,0.4)    ;
    backdrop-filter: blur(.5px);
  }
}

.container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
}

.limiter {
  max-width: 400px;

  @media screen and (min-width: 1024px) {
      justify-self: flex-end;
  }
}


