@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');


@font-face {
    font-family: Luna-Expanded;
    src: url('../assets/fonts/Luna-ExpandedMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Luna;
    src: url('../assets/fonts/Luna-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Luna;
    src: url('../assets/fonts/Luna-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Luna UltraCondensedBold';
    src: url('../assets/fonts/Luna-UltraCondensedBold.woff2') format('woff2');
    //...
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

// @font-face {
//     font-family: 'Merriweather';
//     font-style: italic;
//     font-weight: 300;
//     font-display: swap;
//     src: url('../assets/fonts/Merriweather-Italic.woff2') format('woff2');

//   }


//   @font-face {
//     font-family: 'Merriweather';
//     font-style: italic;
//     font-weight: 700;
//     font-display: swap;
//     src: url('../assets/fonts/Merriweather-Italic.woff2') format('woff2');

//   }

//   @font-face {
//     font-family: 'Merriweather';
//     font-style: italic;
//     font-weight: 900;
//     font-display: swap;
//     src: url('../assets/fonts/Merriweather') format('woff2');

//   }

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;

    font-display: swap;
    src: url('../assets/fonts/Merriweather-Regular.woff2') format('woff2');

  }

  @font-face {
    font-family: 'Merriweather-Bold';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../assets/fonts/Merriweather-Bold.woff2') format('woff2');

  }

  @font-face {
    font-family: 'Merriweather-Light';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../assets/fonts/Merriweather-Light.woff2') format('woff2');

  }


//   @font-face {
//     font-family: 'Merriweather';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url('../assets/fonts/Merriweather-Regular.woff2') format('woff2');

//   }

 
//   @font-face {
//     font-family: 'Merriweather';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url('../assets/fonts/Merriweather-Regular.woff2') format('woff2');

//   }

 

//   @font-face {
//     font-family: 'PT Sans';
//     font-style: italic;
//     font-weight: 400;
//     font-display: swap;
//     src: url(../assets/fonts/) format('woff2');

//   }

//   @font-face {
//     font-family: 'PT Sans';
//     font-style: italic;
//     font-weight: 700;
//     font-display: swap;
//     src: url('../assets/fonts/PTSans-Italic.woff2') format('woff2');

//   }


  @font-face {
    font-family: 'PTSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/PTSans-Regular.woff2') format('PTSans-Italic.woff2') format('woff2');

  }

  @font-face {
    font-family: 'PTSans-Bold';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../assets/fonts/PTSans-Bold.woff2') format('PTSans-.woff2') format('woff2');

  }

//   @font-face {
//     font-family: 'PT Sans';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url('../assets/fonts/PTSans-Regular.woff2') format('woff2');

//   }
