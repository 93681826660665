.container {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1500px;
    width: 100%;
}

.narrowContainer {
    max-width: 1100px;
}

.wideContainer {
    max-width: 1600px;
}

@media screen and (max-width: 1024px) {
    .hero-medium {
        margin-left: 20px;
    }
}

@media screen and (max-width: 600px) {
    .hero-small {
        margin-left: 10px;
    }
}